import React from 'react';
import styled from 'styled-components';

import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';
import boxShadows from '../../../Shared/boxShadows';
import breakpoints from '../../../Shared/breakpoints';

const Container = styled.div`
  border-radius: 6px;
  margin: ${({ narrow }) => (narrow ? '12px 6px' : '12px')};
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  z-index: ${({ active }) => (active ? '1' : 'auto')};
  transform: ${({ active }) => (active ? 'scale(1.15)' : 'none')};
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  box-shadow: ${({ weddingStyle, active }) =>
    active
      ? `0px 0px 0px 4px ${defaultStyles[weddingStyle].colors.primary.onWhite}, ${boxShadows.z3}`
      : `0px 0px 0px 1px ${defaultStyles[weddingStyle].colors.primary.onWhite}, ${boxShadows.z1}`};
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    margin: 6px;
    border: ${({ active }) => (active ? 'solid 1px' : 'solid 1px')};
    transform: ${({ active }) => (active ? 'scale(1.05)' : 'none')};
  }
`;

const Image = styled.div`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: ${({ smallHeight }) => (smallHeight ? '80px' : '110px')};
  width: ${({ narrow }) => (narrow ? '135px' : '150px')};
  background-size: ${({ narrow }) => (narrow ? '165%' : 'cover')};
  background-repeat: no-repeat;
  background-position: ${({ narrow }) => (narrow ? '-70px -12px' : 'center')};
  background-image: ${({ image }) => `url(${image})`};
  border: ${({ active }) => (active ? 'solid #ffffff 1px !important' : 'none')};
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    width: ${({ narrow }) => (narrow ? '85px' : '100px')};
    height: 70px;
    background-position: ${({ narrow }) => (narrow ? '-35px -6px' : 'center')};
  }
`;
const Text = styled.span`
  color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.secondary.base} !important;
`;

const IconBox = ({
  active = false,
  image,
  text,
  selectValue,
  onSelect,
  question,
  weddingStyle = 'eurehochzeitonline',
  smallHeight,
  narrow = false,
}) => {
  return (
    <Container
      active={active}
      narrow={narrow}
      weddingStyle={weddingStyle}
      onClick={() => onSelect({ value: selectValue, question })}
    >
      <Image
        image={image}
        narrow={narrow}
        active={active}
        smallHeight={smallHeight}
        className="image-in-icon-container"
      />
      <Text weddingStyle={weddingStyle}>{text}</Text>
    </Container>
  );
};

export default IconBox;
