import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import boxShadows from '../../../Shared/boxShadows';
import EditingContainerForCard from '../Editing/Components/EditingContainer/EditingContainerForCard';
import EditingContext from '../../../Shared/Context/EditingContext';

const Container = styled.div`
  text-align: center;
  width: 100%;
  margin: 20px auto;
  background-color: #ffffffe0;
  border-top-left-radius: 12px;
  box-shadow: ${boxShadows.z2};
  position: relative;
  display: ${({ hide }) => {
    return hide ? 'none' : 'block';
  }};
  h1,
  h2,
  h3,
  h4,
  p {
    opacity: ${({ substituteLanguage }) => (substituteLanguage ? '0.7' : '1')};
  }
`;

const ContentCardContainer = ({
  editingKeys,
  children,
  content,
  hideEditingContainer,
  deletionDisabled,
  substituteLanguage,
}) => {
  const { editingModeActive } = useContext(EditingContext);
  const [loadingSave, setLoadingSave] = useState(false);
  return (
    <Container
      className="color-background-white"
      substituteLanguage={substituteLanguage}
      hide={content?.presents?.length === 0 && !editingModeActive}
    >
      {hideEditingContainer ? (
        <>{children}</>
      ) : (
        <EditingContainerForCard
          editingKeys={editingKeys}
          content={content}
          loadingSave={loadingSave}
          setLoadingSave={setLoadingSave}
          deletionDisabled={deletionDisabled}
        >
          {children}
        </EditingContainerForCard>
      )}
    </Container>
  );
};

export default ContentCardContainer;
