import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';

import boxShadows from '../../../../../../Shared/boxShadows';
import { ReactComponent as AmazonSvg } from '../../../../../../_assets/svg/amazon.svg';
import breakpoints from '../../../../../../Shared/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  position: relative;
  box-shadow: ${boxShadows.z2};
  background-color: #fff;
  border-radius: 0;
  border-top-left-radius: 12px;
  margin: 21px;
  padding: 9px;
  overflow: hidden;
  h2,
  p {
    margin: 0;
    text-align: left;
    max-width: 95%;
    @media (max-width: ${breakpoints.xs}) {
      margin: 6px;
    }
  }
  h2 {
    font-size: 21px;
    font-weight: bold;
  }
  p {
    font-size: 15px;
  }
  @media (max-width: ${breakpoints.xs}) {
    flex-direction: column;
  }
  h1,
  h2,
  h3,
  h4,
  p {
    opacity: ${({ substituteLanguage }) => (substituteLanguage ? '0.7' : '1')};
  }
`;

const PriceTag = styled.div`
  font-size: 14px !important;
`;

const Image = styled.div`
  flex: 1;
  height: 100px;
  width: 150px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
  border-top-left-radius: 12px;
  box-shadow: ${boxShadows.z2};
  @media (max-width: ${breakpoints.xs}) {
    flex: unset;
    height: 200px;
    width: 100%
    margin-bottom: 12px;
  }
`;
const MiddleContainer = styled.div`
  flex: 4;
`;
const RightContainer = styled.div`
  flex: 1.5;
  opacity: ${({ alreadyBacked }) => (alreadyBacked ? '0.3' : '1.0')};
  padding-bottom: ${({ backedByUser }) => (backedByUser ? '50px' : '0')};
  @media (max-width: ${breakpoints.xs}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 12px;
    align-items: center;
    padding-bottom: 20px;
  }
`;

const SubmitBackingButton = styled(Button)`
  margin: 12px auto;
  width: 160px;
  height: 30px;
  box-shadow: ${boxShadows.z2};
`;

const AmazonButton = styled(Button)`
  height: 30px;
  width: 160px;
  box-shadow: ${boxShadows.z2};
  font-weight: bold;
  svg {
    height: 21px;
    width: 21px;
    margin-right: 9px;
  }
`;

const AlreadyBackedLabel = styled.div`
  position: absolute;
  top: 30px;
  right: -70px;
  padding: 6px;
  transform: rotate(35deg);
  width: 270px;
  font-size: 14px !important;
  background-color: #e5e5e5;
`;

const DeleteButton = styled.p`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 12px;
  font-size: 13px !important;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const SinglePresentCard = ({
  present,
  submitBacking,
  loadingSubmit,
  loadingIfBackingSubmitted,
  deleteBacking,
  substituteLanguage,
}) => {
  return (
    <Container substituteLanguage={substituteLanguage}>
      <Image imageUrl={present.imageUrl}></Image>
      <MiddleContainer>
        <h2>{present.name}</h2>
        <p>{present.details}</p>
      </MiddleContainer>
      <RightContainer alreadyBacked={present.backing} backedByUser={present.backing?.username}>
        {present.price && (
          <PriceTag>
            ~ {present.price} {i18n.t(k.EUR)}
          </PriceTag>
        )}
        <SubmitBackingButton
          color="primary"
          variant="contained"
          onClick={() => {
            submitBacking(present.id);
          }}
          disabled={loadingSubmit || loadingIfBackingSubmitted || !!present.backing}
        >
          {loadingSubmit || loadingIfBackingSubmitted ? (
            <CircularProgress size={20}></CircularProgress>
          ) : (
            <>{i18n.t(k.ADD_BACKING)}</>
          )}
        </SubmitBackingButton>
        {present.amazonUrl && (
          <AmazonButton
            href={present.amazonUrl}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            variant="contained"
          >
            <AmazonSvg></AmazonSvg>
            {i18n.t(k.BUY)}
          </AmazonButton>
        )}
      </RightContainer>
      {present.backing && (
        <AlreadyBackedLabel>
          {present.backing.username ? (
            <>
              {i18n.t(k.YOUR_BACKING_SAVED)} <br></br>
              ❤️ {i18n.t(k.THANK_YOU)} ❤️
            </>
          ) : (
            <>{i18n.t(k.A_GUEST_ALREADY_BACKED)}</>
          )}
        </AlreadyBackedLabel>
      )}

      {present.backing?.username && (
        <DeleteButton
          onClick={() => {
            deleteBacking(present.id);
          }}
        >
          {i18n.t(k.DELETE_MY_BACKING)}
        </DeleteButton>
      )}
    </Container>
  );
};

export default SinglePresentCard;
