import styled from 'styled-components';

import breakpoints from '../../../../../Shared/breakpoints';
import boxShadows from '../../../../../Shared/boxShadows';

export default styled.div`
  max-width: 400px;
  position: relative;
  border-top: ${({ backgroundColor, highlighted }) =>
    highlighted ? `solid 2px ${backgroundColor} !important` : 'solid 2px #dddddd !important'};
  border-right: ${({ backgroundColor, highlighted }) =>
    highlighted ? `solid 6px ${backgroundColor} !important` : 'solid 6px #dddddd !important'};
  border-bottom: ${({ backgroundColor, highlighted }) =>
    highlighted ? `solid 2px ${backgroundColor} !important` : 'solid 2px #dddddd !important'};
  background-color: white;
  box-shadow: ${() => `${boxShadows.z2}`};
  margin: 6px 0;
  padding: 6px;
  cursor: pointer;
  @media (max-width: ${breakpoints.xs}) {
    border-left: ${({ backgroundColor, highlighted }) =>
      highlighted ? `solid 6px ${backgroundColor} !important` : 'solid 6px #dddddd !important'};
  }
  h3 {
    font-size: 24px;
    margin: 6px;
  }
  div {
    line-height: 14px;
    span {
      font-size: 14px;
    }
  }
  a {
    margin-top: 0;
    font-size: 12px;
  }
  p {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0;
    opacity: ${({ substituteLanguage }) => (substituteLanguage ? '0.7' : '1')};
  }
  :hover {
    background-color: #fafafa;
  }
`;
