import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useContext, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import defaultStyles from '../../../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../../../Shared/WeddingStyles/GenericStyleContainer';
import WeddingContext from '../../../../Shared/Context/WeddingContext';
import { ReactComponent as CloseIcon } from '../../../../_assets/svg/close-simple.svg';

import breakpoints from '../../../../Shared/breakpoints';
import ConfirmDialog from '../../../../Shared/Components/Dialog/ConfirmDialog';
import AlertDialog from '../../../../Shared/Components/Dialog/AlertDialog';

const StyledDialog = styled(Dialog)`
  @media (max-width: ${breakpoints.sm}) {
    .MuiDialog-paper {
      margin: 0;
      max-height: 100%;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  width: 1000px;
  max-width: 85vw;
  @media (max-width: ${breakpoints.sm}) {
    max-width: calc(100vw - 18px);
    padding: 9px;
    margin-top: -21px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    margin: 0;
  }
  .suggestion {
    margin: 0 9px;
  }
  .MuiTextField-root {
    margin: 30px 0;
    width: 330px;
    input {
      text-align: center;
      padding-bottom: 6px;
      height: 32px;
      font-size: 20px;
      font-weight: bold;
    }
    .MuiInputAdornment-root {
      margin-bottom: -27px;
      margin-top: 0;
      p {
        font-size: 16px;
      }
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
  button {
    width: 330px;
    height: 42px;
    svg {
      height: 25px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
  }
`;

const CloseButton = styled(IconButton)`
  display: block;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 10;
  svg {
    height: 15px;
    width: 15px;
  }
`;

const DomainDialog = () => {
  const history = useHistory();
  const weddingContext = useContext(WeddingContext);
  const { weddingConfig, weddingId } = useContext(WeddingContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingDomainCheck, setLoadingDomainCheck] = useState(false);
  const [loadingDomainRegistration, setLoadingDomainRegistration] = useState(false);

  const [searchedTermAvailable, setSearchedTermAvailable] = useState(null);
  const [checkedDomain, setCheckedDomain] = useState('');
  const [showIrreversibilityWarning, setShowIrreversibilityWarning] = useState(false);
  const [suggestedDomains, setSuggestedDomains] = useState([]);
  const [registrationRequestSaved, setRegistrationRequestSaved] = useState(false);

  const startDomainCheck = async () => {
    setLoadingDomainCheck(true);
    try {
      const { data: domain } = await axios.get(`${weddingContext.weddingId}/domain-service/check/${searchTerm}`);
      setSearchedTermAvailable(domain.available);
      if (domain.available) {
        setCheckedDomain(domain.domainName);
        setSuggestedDomains([]);
      } else {
        setSuggestedDomains(domain.suggestedDomains);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.error || error.message);
    }
    setLoadingDomainCheck(false);
  };

  const startDomainRegistration = async () => {
    setLoadingDomainRegistration(true);
    try {
      const { data: domain } = await axios.post(`${weddingContext.weddingId}/domain-service/register`, {
        domain: checkedDomain,
      });
      if (domain.operationId) {
        setRegistrationRequestSaved(true);
        setShowIrreversibilityWarning(false);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.error || error.message);
    }
    setLoadingDomainRegistration(false);
  };

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    history.push(`/${weddingId}/`);
  };

  return (
    <StyledDialog
      open
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="{messageTitle}"
      aria-describedby="{messageDescriptionText}"
      className="color-background-white"
    >
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingConfig.weddingStyle]}>
        <DialogTitle>
          <CloseButton onClick={() => handleClose()}>
            <CloseIcon></CloseIcon>
          </CloseButton>
        </DialogTitle>
        {searchedTermAvailable === true ? (
          <StyledDialogContent className="color-background-white">
            <h2>{i18n.t(k.DOMAIN_AVAILABLE_TITLE)}</h2>
            <p>
              <strong>www.{checkedDomain}</strong> {i18n.t(k.DOMAIN_AVAILABLE_DESCRIPTION)}
            </p>
            <br></br>
            <StyledDialogActions className="color-background-white">
              <Button
                color="secondary"
                onClick={() => {
                  setCheckedDomain('');
                  setSearchedTermAvailable(false);
                  setSearchTerm('');
                }}
                variant="contained"
              >
                {i18n.t(k.DOMAIN_BACK)}
              </Button>
              <Button color="primary" variant="contained" onClick={() => setShowIrreversibilityWarning(true)}>
                {i18n.t(k.DOMAIN_BOOK)}
              </Button>
            </StyledDialogActions>
          </StyledDialogContent>
        ) : (
          <StyledDialogContent className="color-background-white">
            <h2>{i18n.t(k.DOMAIN_SEARCH_TITLE)}</h2>
            <p>{i18n.t(k.DOMAIN_SEARCH_DESCRIPTION)}</p>
            <form
              onSubmit={e => {
                e.preventDefault();
                startDomainCheck();
              }}
            >
              <TextField
                autoFocus
                type="text"
                variant="filled"
                value={searchTerm}
                onChange={event => setSearchTerm(event.target.value)}
                label={i18n.t(k.DOMAIN_SEARCH_LABEL)}
                required
                InputProps={{
                  endAdornment: <InputAdornment position="end">.de</InputAdornment>,
                  startAdornment: <InputAdornment position="start">www.</InputAdornment>,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {searchedTermAvailable === false && loadingDomainCheck === false && (
                <>
                  <p>{i18n.t(k.DOMAIN_NOT_AVAILABLE)}</p>
                  <p>
                    {suggestedDomains.map(domain => (
                      <span className="suggestion">www.{domain}</span>
                    ))}
                  </p>
                </>
              )}
              <StyledDialogActions className="color-background-white">
                <Button type="submit" color="primary" variant="contained" disabled={loadingDomainCheck}>
                  {loadingDomainCheck ? (
                    <CircularProgress size="15"></CircularProgress>
                  ) : (
                    <>{i18n.t(k.DOMAIN_CHECK_AVAILABILITY)}</>
                  )}
                </Button>
              </StyledDialogActions>
            </form>
          </StyledDialogContent>
        )}
        <ConfirmDialog
          open={showIrreversibilityWarning}
          messageTitle={i18n.t(k.DOMAIN_CONFIRM_TITLE)}
          messageDescriptionText={i18n.t(k.DOMAIN_CONFIRM_DESCRIPTION)}
          handleCancel={() => {
            setShowIrreversibilityWarning(false);
          }}
          handleConfirm={() => {
            startDomainRegistration();
          }}
          loadingConfirm={loadingDomainRegistration}
        ></ConfirmDialog>
        <AlertDialog
          open={registrationRequestSaved}
          messageTitle={i18n.t(k.DOMAIN_REGISTRATION_SUCCESS_TITLE)}
          messageDescriptionText={i18n.t(k.DOMAIN_REGISTRATION_SUCCESS_DESCRIPTION)}
          confirmButtonText={i18n.t(k.OK)}
          handleConfirm={() => {
            setRegistrationRequestSaved(false);
            history.push(`/${weddingId}/`);
          }}
        ></AlertDialog>
        <AlertDialog
          open={errorMessage}
          messageDescriptionText={i18n.t(k.ERROR_OCCURED_WITH_MESSAGE) + errorMessage}
          confirmButtonText={i18n.t(k.OK)}
          handleConfirm={() => {
            setErrorMessage('');
          }}
        ></AlertDialog>
      </GenericStyleContainer>
    </StyledDialog>
  );
};

export default DomainDialog;
